:root {
    --mainTheme: #1b75bc !important;
    --colorGrey: #808285 !important;
    --colorBlack: #222222 !important;
    --colorWhite: #ffffff !important;
}
body {
    font-family: 'Barlow Condensed', sans-serif !important;
}
p {
    font-family: 'Poppins', sans-serif;
}
input,
select,
button {
    outline: none;
    box-shadow: none;
}
input:focus,
select:focus,
button:focus,
textarea:focus {
    outline: none;
    box-shadow: none !important;
}
.btn:focus {
    outline: 0;
    box-shadow: none;
}
a,
a:hover,
a:focus,
a:visited {
    text-decoration: none !important;
}
ul,
ol {
    padding: 0 !important;
    margin: 0 !important;
    list-style: none;
}
/*-- Start Loader --*/
.mypreloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: var(--colorWhite);
    text-align: center;
}
.mypreloader img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    animation: zoom-in-zoom-out 4s ease infinite;
}
@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.5, 1.5);
    }
    100% {
        transform: scale(1, 1);
    }
}
/*-- End Loader --*/
/*-- Start Button --*/
.thm-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    outline: none !important;
    background-color: var(--mainTheme);
    font-size: 16px;
    font-weight: 600;
    color: var(--colorWhite);
    text-transform: uppercase;
    padding: 17px 60px;
    transition: all 0.3s linear;
    overflow: hidden;
    z-index: 1;
    letter-spacing: 0.1em;
    border-radius: 5px;
}
.thm-btn:hover {
    color: var(--mainTheme);
}
.thm-btn:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--colorWhite);
    transform: scale(0);
    transition: all 0.2s linear;
    border-radius: 5px;
    opacity: 1;
    z-index: -1;
}
.thm-btn:hover:after {
    opacity: 1;
    transform: scale(1);
    transition: all 0.4s linear;
}
/*-- End Button --*/
/*-- Start Button --*/
.theme-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    outline: none !important;
    background-color: var(--colorWhite);
    font-size: 16px;
    font-weight: 600;
    color: var(--colorWhite);
    text-align: center;
    text-transform: uppercase;
    padding: 17px 50px;
    transition: all 0.3s linear;
    overflow: hidden;
    z-index: 1;
    letter-spacing: 0.1em;
    border-radius: 5px;
}
.theme-btn:hover {
    color: var(--mainTheme);
}
.theme-btn:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--colorBlack);
    transform: scale(0);
    transition: all 0.2s linear;
    border-radius: 5px;
    opacity: 1;
    z-index: -1;
}
.theme-btn:hover:after {
    opacity: 1;
    transform: scale(1);
    transition: all 0.4s linear;
}
/*-- End Button --*/
/*-- Start Button --*/
.thm-btn-black {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    outline: none !important;
    background-color: var(--mainTheme);
    font-size: 16px;
    font-weight: 600;
    color: var(--colorWhite);
    text-transform: uppercase;
    padding: 17px 60px;
    transition: all 0.3s linear;
    overflow: hidden;
    z-index: 1;
    letter-spacing: 0.1em;
    border-radius: 5px;
}
.thm-btn-black:hover {
    color: var(--colorWhite);
}
.thm-btn-black:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--colorBlack);
    transform: scale(0);
    transition: all 0.2s linear;
    border-radius: 5px;
    opacity: 1;
    z-index: -1;
}
.thm-btn-black:hover:after {
    opacity: 1;
    transform: scale(1);
    transition: all 0.4s linear;
}
/*-- End Button --*/
/*-- Start Animate Border --*/
.animate-border {
    position: relative;
    display: block;
    width: 50px;
    height: 3px;
    text-align: center;
    background: #0869b0;
    margin-top: 10px;
}
.animate-border:after {
    position: absolute;
    content: "";
    width: 35px;
    height: 3px;
    left: 0;
    bottom: 0;
    border-left: 8px solid var(--colorBlack);
    border-right: 8px solid var(--colorBlack);
    animation: animborder 2s linear infinite;
}
@keyframes animborder {
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(50px);
    }
}
.animate-border.border-white:after {
    border-color: var(--colorWhite);
}
.animate-border.border-yellow:after {
    border-color: #F5B02E;
}
.animate-border.border-orange:after {
    border-right-color: #007bff;
    border-left-color: #007bff;
}
.animate-border.border-ash:after {
    border-right-color: #EEF0EF;
    border-left-color: #EEF0EF;
}
.animate-border.border-offwhite:after {
    border-right-color: #F7F9F8;
    border-left-color: #F7F9F8;
}
/*-- End Animate Border --*/
/*-- Start Header --*/
.header {
    position: fixed;
    z-index: 3;
    width: 100%;
    box-shadow: 0 1px 0 0 rgb(255 255 255 / 10%);
    transition: .3s ease-in;
}
.header .web-header {
    padding: 10px 0;
}
.header-bg {
    background: var(--colorBlack);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    height: 95px;
}
.header-logo img {
    width: auto;
    height: 75px;
}
.header-contact {
    position: relative;
    display: flex;
    align-items: center;
    float: right;
}
.header-content {
    margin-left: 15px;
}
.header-content p {
    font-size: 13px;
    line-height: 14px;
    color: var(--colorWhite);
    margin-bottom: 0;
}
.header-content h5 {
    margin-bottom: 0;
}
.header-content h5 a {
    color: var(--colorWhite);
}
.header-content h5 a:hover {
    color: var(--mainTheme);
}
.header li.nav-item {
    padding: 10px 0px !important;
    position: relative;
    margin: 0px 18px;
}
.header .nav-link {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--colorWhite);
    padding: 0px !important;
}
.header .header-btn a {
    background-color: var(--mainTheme);
    border: 2px solid var(--mainTheme);
    padding: 13px 26px;
    margin-left: 18px;
    font-size: 16px;
    letter-spacing: 2px;
    color: var(--colorWhite);
}
.header .header-btn a:hover {
    background-color: var(--colorWhite);
    color: #2a2b2f;
    transition: .4s;
}
.header .dropdown-toggle::after {
    border: none;
    display: block;
}
.header .dropdown-menu {
    position: relative;
}
.header .dropdown-toggle i {
    float: right;
    margin-top: 5px;
    margin-left: 7px;
}
.header .dropdown-menu {
    padding: 10px 0;
    border: none;
    border-radius: 10px;
    background-clip: padding-box;
    box-shadow: 0 0 4px 0 #b7b7b7;
    background-color: var(--colorWhite);
}
.header .dropdown-item {
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 600;
    color: #2a2b2f;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.header .dropdown-item:focus,
.header .dropdown-item:hover {
    color: var(--mainTheme);
    background-color: #f5f5f5;
}
.main-menu .navigation li:hover a,
.main-menu .navigation li a.nav-link.active {
    color: var(--colorWhite);
}
.main-menu .navigation>li>a:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 7px;
    width: 100%;
    border-bottom: 2px solid var(--mainTheme);
    transform: scaleX(0);
    transition: all 500ms ease;
}
.main-menu .navigation li:hover a:before,
.main-menu .navigation li a.nav-link.active:before {
    transform: scale(1);
}
/*-- End Header --*/
/*-- Start Mobile Header --*/
.mobile-header {
    display: none;
}
.mobile-header .sidepanel {
    width: 80%;
    position: fixed;
    z-index: 3;
    height: 100%;
    top: 0;
    right: 0;
    background-color: var(--colorBlack);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 45px;
    display: none; 
}
.mobile-header .closebtn {
	visibility: hidden;
	opacity: 0;
    border: none;
    background: transparent;
    font-size: 34px;
    color: var(--mainTheme);
}
.mobile-header .sidepanel a.closebtn i {
    padding: 15px 10px 0px 0px;
    text-decoration: none;
    font-size: 36px;
    color: var(--mainTheme);
    display: block;
    transition: 0.3s;
}
.mobile-header .sidepanel .closebtn {
    position: absolute;
    top: 6px;
    right: 15px;
}
.mobile-header .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: var(--mainTheme);
    color: white;
    padding: 10px 15px;
    border: none;
    float: right;
    margin: 17px 0;
    border-radius: 5px;
}
.mobile-header .openbtn i {
    float: right;
}
/*-- End Mobile Header --*/
/*-- Start Breadcrumb --*/
.breadcrumb-section {
    position: relative;
    z-index: 1;
    background-image: url(assets/images/about/breadcrumb.webp);
    background-position: center;
    background-size: cover;
    padding-top: 245px;
    padding-bottom: 226px;
}
.breadcrumb-section .breadcrumb-shape {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 225px;
    background-color: transparent;
}
.breadcrumb-section .breadcrumb-text {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: var(--colorWhite);
}
.breadcrumb-section .breadcrumb-text h1 {
    font-size: 72px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colorWhite);
}
.breadcrumb-section .breadcrumb-nav {
    position: absolute;
    bottom: 0;
    z-index: 22;
    left: 50%;
    padding: 20px 25px!important;
    background: var(--mainTheme);
    -webkit-transform: translate(-50%, 40%);
    transform: translate(-50%, 40%);
    text-align: center;
}
.breadcrumb-section .breadcrumb-nav li {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-right: 10px;
    padding-right: 15px;
    position: relative;
}
.breadcrumb-section .breadcrumb-nav li,
.breadcrumb-section .breadcrumb-nav li a {
    color: var(--colorWhite);
}
.breadcrumb-section .breadcrumb-nav li::after {
    position: absolute;
    content: '|';
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.breadcrumb-section .breadcrumb-nav li:last-child {
    margin-right: 0;
    padding-right: 0;
}
.breadcrumb-section .breadcrumb-nav li:last-child::after {
    content: '';
}
/*-- End Breadcrumb --*/
.fancybox-infobar,
.fancybox-button.fancybox-button--play,
.fancybox-button.fancybox-button--thumbs {
    display: none;
}
@media(max-width: 1400px) {
    .header .col-lg-3 {
        width: 20%;
    }
    .header .col-lg-6 {
        width: 60%;
    }
}
@media (min-width: 1200px) {
    .header .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
        left: 0;
        top: 73px;
    }
    .dropdown .dropdown-menu {
        display: block;
        visibility: hidden;
        opacity: 0;
        transform: translateY(20px);
        transition: all .3s ease-in
    }
    .dropdown:hover>.dropdown-menu {
        visibility: visible;
        transform: scaleY(1);
        opacity: 1;
        visibility: visible;
    }
    .dropdown-submenu:hover .dropdown-menu {
        visibility: visible;
        transform: scaleY(1);
        visibility: visible;
        opacity: 1;
    }
}
@media(max-width: 1199.98px) {
    .header li.nav-item {
        padding: 10px 0px !important;
    }
    .header .header-btn a {
        margin-left: 0px;
        margin-top: 10px;
        margin-bottom: 15px;
        display: inline-block;
    }
    .header li.nav-item {
        margin: 0px 0px;
    }
    .header .nav-item a.active {
        display: inline-block;
        color: var(--mainTheme);
        font-weight: 500;
    }
    .header .nav-link:focus, .header .nav-link:hover {
        color: var(--mainTheme);
        font-weight: 500;
    }
    .header .dropdown-menu {
        margin-top: 15px;
    }
    .header .dropdown-toggle i {
        float: none;
    }
    .web-header {
        display: none;
    }
    .mobile-header {
        display: block;
        padding: 10px 0;
    }
    .mobile-header .closebtn {
		visibility: visible;
		opacity: 1;
	}
    .mobile-header .sidepanel {
        display: none;
    }
    .mobile-header .responsive_nav {
        display: block;
    }
    .breadcrumb-section .breadcrumb-text h1 {
        font-size: 54px;
    }
    .breadcrumb-section {
        padding-top: 200px;
        padding-bottom: 190px;
    }
}
@media(max-width: 992px) {
    .breadcrumb-section .breadcrumb-text h1 {
        font-size: 50px;
    }
    .breadcrumb-section {
        padding-top: 160px;
        padding-bottom: 150px;
    }
}
@media(max-width: 768px) {
    .breadcrumb-section .breadcrumb-text h1 {
        font-size: 44px;
    }
    .breadcrumb-section {
        padding-top: 140px;
        padding-bottom: 130px;
    }
}
@media(max-width: 576px) {
    .breadcrumb-section .breadcrumb-text h1 {
        font-size: 36px;
    }
    .breadcrumb-section {
        padding-top: 160px;
        padding-bottom: 130px;
    }
}
@media(max-width: 430px) {
    .header-logo img {
        height: 50px;
    }
    .header-bg {
        height: 70px;
    }
    .mobile-header .openbtn {
        margin: 5px 0;
    }
}
@media(max-width: 400px) {
    .breadcrumb-section .breadcrumb-nav {
        width: 65%;
    }
}
.footer-main {
    background-image: url(assets/images/footer-bg.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-color: var(--colorBlack);
    padding: 100px 0 50px 0;
}
.footer-link a img {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
}
.footer-social-link {
    margin-top: 40px;
}
.footer-social-link ul {
    padding: 0;
    margin-bottom: 0;
    list-style-type: none;
    display: flex;
}
.footer-social-link ul li {
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: var(--colorWhite);
    line-height: 40px;
    border-radius: 5px;
    margin-right: 10px;
    -webkit-transition: .4s;
    transition: .4s;
    cursor: pointer;
}
.footer-social-link ul li:hover {
    background-color: var(--mainTheme);
}
.footer-social-link ul li a {
    font-size: 24px;
    color: var(--mainTheme);
}
.footer-social-link ul li a i {
    color: var(--mainTheme);
}
.footer-social-link ul li:hover a i {
    color: var(--colorWhite);
}
.footer-social-link ul li:last-child {
    margin-right: 0px;
}
.footer-link h6 {
    font-size: 22px;
    font-weight: 500;
    color: var(--colorWhite);
    margin-bottom: 5px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.footer-link span {
    margin-bottom: 30px;
}
.footer-link p {
    font-size: 16px;
    color: #8b8989;
}
.footer-link ul {
    padding: 0;
    list-style-type: none;
}
.footer-link ul li {
    margin-bottom: 7px;
}
.footer-link ul li a {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    color: #8b8989;
}
.footer-link ul li a:hover {
    color: var(--mainTheme);
    -webkit-transition: .4s;
    transition: .4s;
}
.contact {
    position: relative;
    display: flex;
}
.contact-icon {
    width: 50px;
    height: 50px;
    background-color: var(--mainTheme);
    text-align: center;
    border-radius: 5px;
    position: absolute;
}
.contact-icon img {
    width: auto;
    height: auto;
    padding: 10px;
}
.contact-info {
    margin-left: 70px;
}
.contact-info h5 {
    font-size: 22px;
    color: var(--colorWhite);
    margin-bottom: 5px;
    letter-spacing: 1px;
    margin-top: -3px;
}
.contact-info p {
    font-size: 16px;
    color: #8b8989;
    margin-bottom: 20px;
}
.contact-info p a {
    font-size: 16px;
    color: #8b8989;
    word-break: break-all;
}
.contact-info p a:hover {
    font-size: 16px;
    color: var(--mainTheme);
}
.copy-right {
    margin-top: 60px;
}
.copy-right p {
    font-size: 16px;
    color: var(--colorWhite);
    margin-bottom: 0;
}
.copy-right p a {
    color: var(--mainTheme);
}
/*-- End Footer --*/
@media(max-width: 1200px) {
    .footer-main {
        padding: 80px 0 50px 0;
    }
}
@media(max-width: 992px) {
    .footer-main {
        padding: 60px 0 30px 0;
    }
    .footer-link {
        margin-bottom: 30px;
    }
    .footer-main .justify-content-center {
        justify-content: start !important;
    }
    .copy-right {
        margin-top: 0px;
    }
}
@media(max-width: 767px) {
    .footer-main {
        padding: 50px 0 30px 0;
    }
    .footer-social-link {
        margin-top: 30px;
        display: inline-block;
    }
}