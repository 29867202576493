/*-- Start --*/
.we-are {
    padding: 140px 0 120px;
}
.we-are-content {
    padding-left: 60px;
}
.we-are .section-head h6 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--mainTheme);
    margin-bottom: 15px;
    letter-spacing: 1px;
}
.we-are .section-head h2 {
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colorBlack);
    margin-bottom: 30px;
}
.we-are .section-head h5 {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 500;
    color: var(--mainTheme);
}
.we-are .section-head p {
    font-size: 16px;
    color: var(--colorGrey);
}
.we-are-point ul li {
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
}
.we-are-point ul li .about-icon {
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    background: #f5f5f5;
    color: var(--mainTheme);
    border-radius: 50%;
}
.we-are-point ul li .about-text {
    flex: 1;
    margin-left: 30px;
}
.we-are-point ul li .about-text .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
    color: #0a0c1c;
}
.we-are-point ul li .about-text p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;
    margin-bottom: 0;
}
.we-are .image-column {
    position: relative;
    margin-bottom: 30px;
}
.we-are .image-column .inner {
    position: relative;
    display: block;
}
.we-are .image-column .inner:before {
    content: "";
    position: absolute;
    left: 0;
    width: 15px;
    height: 280px;
    background: var(--mainTheme);
    border-radius: 8px;
}
.we-are .image-column .image-block:nth-child(1) {
    margin-left: 110px;
}
.we-are .image-column .image-block {
    position: relative;
    display: block;
    border-radius: 7px;
    overflow: hidden;
}
.we-are .image-column .image-block img {
    display: block;
    width: 100%;
    border-radius: 10px;
}
.we-are .image-column .image-block:nth-child(2) {
    margin-right: 140px;
    margin-top: -170px;
}
/*-- End --*/

/*-- Start --*/
.video-section {
    position: relative;
    height: 494px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--mainTheme);
}
.video-text .section-head h6 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--colorWhite);
    margin-bottom: 15px;
    letter-spacing: 1px;
}
.video-text .section-head h2 {
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colorWhite);
    margin-bottom: 30px;
}
.video-text p {
    font-size: 16px;
    color: var(--colorWhite);
}
.video-section .video-wrap {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;
}
.video-section .video-wrap .video-bg {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    background-size: cover;
    background-position: center;
    background-image: url(../assets/images/about/office_img.webp);
}
.video-section .video-wrap .video-bg .video-link {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    z-index: 2;
}
.video-section .video-wrap .video-bg .video-link .popup_video {
    position: relative;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #0c61a5;
    font-size: 34px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 100px;
    color: var(--colorWhite);
    display: block;
}
.video-section .video-wrap .video-bg .video-link .popup_video::after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: '';
    background-color: var(--colorWhite);
    z-index: -1;
    border-radius: 50%;
    -webkit-animation: ripple-white 1s linear infinite;
    animation: ripple-white 1s linear infinite;
}
@keyframes ripple {
    0%,
    35% {
        transform: (scale(0));
        opacity: 1;
    }
    50% {
        transform: (scale(1.5));
        opacity: 0.8;
    }
    100% {
        opacity: 0;
        transform: (scale(2));
    }
}
@keyframes ripple-white {
    0% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    40% {
        opacity: 0.5;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
    }
}
/*-- End --*/

/*-- Start --*/
.best-company {
    padding: 120px 0;
    position: relative;
    z-index: 1;
}
.best-company::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background: #eaebee;
    width: 42%;
    height: 100%;
    z-index: -1;
}
.best-company .best-company-box .section-head {
    padding-right: 35px;
    margin-bottom: 50px;
}
.best-company-box .section-head h6 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--mainTheme);
    margin-bottom: 15px;
    letter-spacing: 1px;
}
.best-company-box .section-head h2 {
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colorBlack);
}
.best-company .marketing-image .experience-wrap .experience .experience-text .title {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--colorBlack);
}
.best-company .marketing-image {
    margin-left: 70px;
    padding-top: 300px;
    padding-bottom: 300px;
    background-image: url(../assets/images/about/marketing-img.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
}
.best-company .marketing-image .experience-wrap {
    max-width: 410px;
    position: absolute;
    top: 65px;
    left: -40px;
    z-index: 1;
}
.best-company .marketing-image .experience-wrap .shape-1 {
    height: 90px;
    width: 350px;
    background: var(--mainTheme);
    position: absolute;
    bottom: -10px;
    right: -10px;
    z-index: -1;
}
.best-company .marketing-image .experience-wrap .experience {
    display: flex;
    align-items: center;
    background: var(--colorWhite);
    box-shadow: 0px 0px 98px 0px rgba(0, 0, 0, 0.22);
    padding: 30px 40px;
}
.experience-icon img {
    max-width: 47px;
}
.best-company .marketing-image .experience-wrap .experience .experience-text {
    position: relative;
    padding-left: 35px;
    margin-left: 35px;
    margin-right: 18px;
}
.best-company .marketing-image .experience-wrap .experience .experience-text::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #e1e1e1;
    width: 1px;
    height: 75px;
}
.best-company .marketing-image .experience-wrap .experience .experience-text .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
    color: var(--colorBlack);
    margin-bottom: 0;
}
.best-company-tabs-menu {
    display: flex;
}
@media only screen and (max-width: 767px) {
    .best-company-tabs-menu {
        flex-wrap: wrap;
    }
}
.best-company-tabs-menu li {
    background-color: #f4f5f8;
    position: relative;
}
.best-company-tabs-menu li::before {
    position: absolute;
    content: "";
    border-top: 30px solid var(--mainTheme);
    width: 0;
    height: 0;
    bottom: -25px;
    right: 0;
    border-left: 30px solid transparent;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
}
.best-company-tabs-menu li:nth-child(2) {
    border-right: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
}
@media only screen and (max-width: 449px) {
    .best-company-tabs-menu li:nth-child(2) {
        border-right: 0;
        border-left: 0;
    }
}
.best-company-tabs-menu li .best-company-title {
    width: 190px;
    text-align: center;
    border: 0;
    border-radius: 0;
    padding: 25px 0;
    cursor: pointer;
}
.best-company-tabs-menu li .best-company-title .title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 30px;
    text-transform: uppercase;
    color: var(--colorBlack);
    margin-bottom: 0;
}
.best-company-tabs-menu li.active {
    color: var(--colorWhite);
    background-color: var(--mainTheme);
}
.best-company-tabs-menu li.active::before {
    opacity: 1;
    visibility: visible;
}
.best-company-tabs-menu li.active .best-company-title .title {
    color: var(--colorWhite);
}
.best-company-tab-pane {
    display: none;
}
.best-company-tab-pane.active {
    display: block;
}
.best-company-tab-content {
    margin-top: 40px;
}
.best-company-tab-content .best-company-tab-pane .best-company-item {
    padding-right: 130px;
}
@media only screen and (max-width: 1399px) {
    .best-company-tab-content .best-company-tab-pane .best-company-item {
        padding-right: 55px;
    }
}
@media only screen and (max-width: 1199px) {
    .best-company-tab-content .best-company-tab-pane .best-company-item {
        padding-right: 83px;
    }
}
@media only screen and (max-width: 767px) {
    .best-company-tab-content .best-company-tab-pane .best-company-item {
        padding-right: 0;
    }
}
.best-company-tab-content .best-company-tab-pane .best-company-item .title {
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
    color: var(--colorBlack);
}
.best-company-tab-content .best-company-tab-pane .best-company-item p {
    font-size: 16px;
    color: var(--colorGrey);
    margin-top: 25px;
}
@media only screen and (max-width: 1199px) {
  .best-company .marketing-image {
    margin-left: 0;
    margin-top: 50px;
  }
}
@media only screen and (max-width: 991px) {
    .best-company .marketing-image {
        padding-top: 250px;
        padding-bottom: 250px;
    }
}
@media only screen and (max-width: 767px) {
    .best-company .marketing-image {
        padding-top: 200px;
        padding-bottom: 200px;
    }
}
@media only screen and (max-width: 575px) {
    .best-company .marketing-image .experience-wrap .experience {
        padding: 30px 35px;
    } 
    .best-company .marketing-image .experience-wrap .experience .experience-text {
        padding-left: 30px;
        margin-left: 30px;
        margin-right: 0px;
    }
}
.best-company-tab-pane {
	display: none;
}
.active-content {
	display: block;
}
/*-- End --*/

/*-- Start --*/
.quality-work {
    position: relative;
    padding: 120px 0px;
    background: url(../assets/images/home/pattern-bg.png) left top repeat;
    overflow: hidden;
}
.quality-work .section-head {
    margin-bottom: 50px;
}
.quality-work .section-head h6 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--mainTheme);
    margin-bottom: 15px;
    letter-spacing: 1px;
}
.quality-work .section-head h2 {
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colorBlack);  
}
.quality-work .skill-img {
    position: relative;
    z-index: 1;
}
.quality-work .skill-img::after {
    position: absolute;
    left: -25px;
    bottom: -25px;
    width: 180px;
    height: 180px;
    background-color: var(--mainTheme);
    content: '';
    -webkit-clip-path: polygon(100% 0, 0 100%, 100% 100%);
    clip-path: polygon(0 0, 0 100%, 100% 100%);
    z-index: -1;
}
.quality-work .skill-box {
    width: 100%;
    margin: 25px 0;
}
.quality-work .skill-box .title{
    display: block;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
}
.quality-work .skill-box .skill-bar{
    width: 100%;
    height: 7px;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(0,0, 0, 0.1);
}
.quality-work .skill-box .skill-per {
    position: relative;
    display: block;
    height: 100%;
    width: 95%;
    border-radius: 6px;
    background: var(--mainTheme);
    animation: progress 0.8s ease-in-out forwards;
    opacity: 0;
}
.quality-work .skill-per .tooltip{
    position: absolute;
    right: 40px;
    top: -32px;
    margin-right: -40px;
    bottom: 18px;
    width: 40px;
    height: 22px;
    background: var(--colorBlack);
    color: var(--colorWhite);
    line-height: 22px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Barlow Condensed', sans-serif;
    border-radius: 5px;
    text-align: center;
    z-index: 1;
    opacity: 1;
}
.quality-work .skill-per .tooltip::before {
    content: "";
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: -5px;
    border: 5px solid transparent;
    border-top: 5px solid var(--colorBlack);
    border-right: 5px solid var(--colorBlack);
}
.quality-work .skill-per.fit-purpose {
    width: 93%;
    animation-delay: 0.4s;
}
.quality-work .skill-per.conf-req{
    width: 89%;
    animation-delay: 0.3s;
}
.quality-work .skill-per.completeness{
    width: 99%;
    animation-delay: 0.4s;
}
.quality-work .skill-per.correctness{
    width: 95%;
    animation-delay: 0.3s;
}
.quality-work .skill-per.diligence{
    width: 92%;
    animation-delay: 0.3s;
}
.quality-work .skill-per.professional{
    width: 100%;
    animation-delay: 0.4s;
}
.quality-work .skill-per.communication{
    width: 97%;
    animation-delay: 0.4s;
}
@keyframes progress {
    0% {
        width: 0;
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
/*-- End --*/

/*-- Start --*/
.project-started {
    position: relative;
    display: block;
    padding: 120px 0;
    overflow: hidden;
    background-color: var(--colorBlack);
}
.project-started-shape-1 {
    position: absolute;
    top: -333px;
    right: 50px;
    width: 495px;
    height: 360px;
    background-color: var(--mainTheme);
    mix-blend-mode: screen;
    border-radius: 30px;
    z-index: 1;
    transform: rotate(45deg);
    animation: rectMove 9s linear 0ms infinite;
}
.project-started-shape-2 {
    position: absolute;
    top: -175px;
    right: -350px;
    width: 495px;
    height: 360px;
    background-color: var(--colorBlack);
    mix-blend-mode: soft-light;
    border-radius: 30px;
    transform: rotate(45deg);
    animation: rectMove 9s linear 1000ms infinite;
}
.project-started-shape-3 {
    position: absolute;
    top: 313px;
    left: -184px;
    width: 495px;
    height: 360px;
    background-color: var(--colorWhite);
    mix-blend-mode: soft-light;
    border-radius: 30px;
    z-index: 1;
    transform: rotate(45deg);
    animation: rectMove 9s linear 0ms infinite;
}
.project-started-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--mainTheme);
    border-radius: 10px;
    padding: 90px 80px 90px;
    overflow: hidden;
}
.project-started-left {
    position: relative;
    display: block;
}
.project-started-title {
    font-size: 40px;
    color: var(--colorWhite);
    text-transform: uppercase;
    line-height: 48px;
}
.project-started-right {
    position: relative;
    display: block;
}
.project-started-btn {
    color: var(--colorBlack);
    background-color: var(--colorWhite);
}
.project-started-btn:hover {
    color: var(--colorWhite);
    background-color: var(--colorBlack);
}
/*-- End --*/

@media(max-width: 1400px){
    .we-are {
        padding: 120px 0 100px;
    }
    .we-are .section-head h2 {
        font-size: 56px;
    }
    .video-text .section-head h2 {
        font-size: 56px;
    }
    .best-company {
        padding: 100px 0;
    }
    .best-company-box .section-head h2 {
        font-size: 56px;
    }
    .quality-work {
        padding: 100px 0px;
    }
    .quality-work .section-head h2 {
        font-size: 56px;
    }
}
@media(max-width: 1200px) {
    .we-are {
        padding: 100px 0 80px;
    }
    .we-are-content {
        padding-left: 0px;
    }  
    .we-are .section-head h2 {
        font-size: 50px;
    }    
    .video-text .section-head h2 {
        font-size: 50px;
    }
    .best-company {
        padding: 80px 0;
    }
    .best-company-box .section-head h2 {
        font-size: 50px;
    }
    .best-company-tabs-menu li .best-company-title {
        width: 150px;
    }
    .best-company .marketing-image .experience-wrap {
        max-width: 370px;
    }
    .best-company .marketing-image .experience-wrap .experience {
        padding: 30px 30px;
    }
    .best-company .marketing-image .experience-wrap .experience .experience-text {
        padding-left: 30px;
        margin-left: 30px;
    }
    .best-company .marketing-image .experience-wrap .experience .experience-text .title {
        font-size: 22px;
    }
    .quality-work {
        padding: 80px 0px;
    }
    .quality-work .section-head h2 {
        font-size: 50px;
    }
    .project-started {
        padding: 100px 0 80px;
    }
}
@media(max-width: 992px) {
    .we-are {
        padding: 80px 0 60px;
    }
    .we-are .section-head h2 {
        font-size: 46px;
    }
    .video-section .video-wrap {
        width: 100%;
        height: 300px;
    }
    .video-section .video-wrap .video-bg .video-link {
        top: auto;
        bottom: 0;
        right: 50%;
        -webkit-transform: translate(50%, 50%);
        transform: translate(50%, 50%);
    }
    .video-section {
        height: auto;
        padding-top: 400px;
        padding-bottom: 80px;
    }
    .video-text .section-head h2 {
        font-size: 46px;
    }
    .best-company {
        padding: 60px 0;
    }
    .best-company::before {
        top: 58%;
        width: 100%;
        height: 42%;
        bottom: 0;
        left: 0;
    }
    .best-company-box .section-head h2 {
        font-size: 50px;
    }
    .best-company .best-company-box .section-head {
        padding-right: 0px;
    }
    .best-company-tabs-menu li {
        background-color: #f4f5f8;
        position: relative;
        width: 33.33333333%;
    }
    .best-company-tabs-menu li .best-company-title {
        width: 100%;
    }
    .best-company-tab-content .best-company-tab-pane .best-company-item {
        padding-right: 0;
    }
    .quality-work {
        padding: 60px 0px;
    }
    .quality-work .section-head h2 {
        font-size: 46px;
    }
    .quality-work .ps-5 {
        padding-left: 15px!important;
    }
    .quality-work .skill-img {
        margin-bottom: 70px;
    }
    .project-started-box {
        padding: 70px 50px 70px;
    }
    .project-started {
        padding: 100px 0;
    }
} 
@media(max-width: 768px) {
    .we-are {
        padding: 70px 0 50px;
    }
    .we-are .section-head h2 {
        font-size: 42px;
    }
    .we-are .image-column .inner:before {
        height: 220px;
    }
    .we-are .image-column .image-block:nth-child(1) {
        margin-left: 70px;
    }
    .video-text .section-head h2 {
        font-size: 42px;
    }
    .video-section {
        height: auto;
        padding-top: 400px;
        padding-bottom: 34px;
    }
    .best-company {
        padding: 50px 0;
    }
    .best-company-box .section-head h2 {
        font-size: 42px;
    }
    .best-company-tabs-menu li .best-company-title {
        width: 170px;
    }
    .best-company .marketing-image .experience-wrap {
        top: 50px;
        left: -15px;
    }
    .quality-work {
        padding: 50px 0px;
    }
    .quality-work .section-head h2 {
        font-size: 42px;
    }
    .project-started {
        padding: 80px 0;
    }
    .project-started-box {
        display: block;
        text-align: center;
    }
    .project-started-title {
        margin-right: 0px;
        margin-bottom: 30px;
        font-size: 36px;
    }
}
@media(max-width: 576px) {
    .we-are .section-head h6 {
        font-size: 22px;
    }
    .we-are .section-head h2 {
        font-size: 32px;
    }
    .we-are .image-column .image-block:nth-child(2) {
        margin-right: 69px;
        margin-top: -180px;
    }
    .we-are .image-column .inner:before {
        width: 12px;
        height: 190px;
    }
    .we-are-point ul li {
        display: block;
    }
    .we-are-point ul li .about-text {
        margin-left: 0px;
        margin-top: 20px;
    }
    .video-text .section-head h2 {
        font-size: 32px;
    }
    .best-company-box .section-head h6 {
        font-size: 22px;
    }
    .best-company-box .section-head h2 {
        font-size: 32px;
    }
    .best-company-tabs-menu li .best-company-title {
        width: auto;
        padding: 10px 0;
    }
    .best-company-tabs-menu li .best-company-title .title {
        font-size: 18px;
    }
    .best-company .marketing-image {
        margin-top: 34px;
    }
    .best-company .marketing-image .experience-wrap {
        max-width: 320px;
        top: 65px;
        left: 0px;
    }
    .best-company .marketing-image .experience-wrap .shape-1 {
        width: 280px;
        bottom: -10px;
        right: -10px;
    }
    .best-company .marketing-image .experience-wrap .experience {
        padding: 20px 20px;
    }
    .best-company .marketing-image .experience-wrap .experience .experience-text {
        padding-left: 20px;
        margin-left: 20px;
        margin-right: 0px;
    }
    .best-company .marketing-image .experience-wrap .experience .experience-text .title {
        font-size: 20px;
    }
    .best-company-tab-content .best-company-tab-pane .best-company-item .title {
        line-height: 28px;
    }
    .best-company .marketing-image .experience-wrap {
        max-width: 260px;
        left: 10px;
    }
    .quality-work {
        padding: 50px 0px;
    }
    .quality-work .section-head h6 {
        font-size: 22px;
    }
    .quality-work .section-head h2 {
        font-size: 36px;
    }
    .skill-img img {
        padding: 0px 0 15px 15px;
    }
    .quality-work .skill-img {
        margin-bottom: 40px;
    }
    .quality-work .skill-img::after {
        bottom: 0px;
        margin: 0px 0px 0px 28px;
    }
}
@media(max-width: 450px){
    .we-are .image-column .image-block:nth-child(2) {
        margin-right: 69px;
        margin-top: -83px;
    }
}
@media(max-width: 360px){
    .we-are .image-column .inner:before {
        height: 130px;
        width: 12px;
    }
}