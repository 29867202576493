/*-- Start --*/
.work-showcase {
    padding: 140px 0 100px;
    background-color: var(--colorWhite);
}
.work-showcase .controls {
    background-color: var(--colorBlack);
    padding: 20px;
    text-align: center;
    position: relative;
    margin-bottom: 80px;
    display: inline-block;
}
.work-showcase .controls::before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 24px;
    top: 100%;
    right: 24px;
    bottom: -15px;
    background-color: var(--mainTheme);
    height: 15px;
}
.controls button {
    padding: 10px 20px;
    background-color: none;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: var(--colorWhite);
    text-transform: uppercase;
}
.controls button.mixitup-control-active {
    color: var(--mainTheme);
}
.controls button:hover {
    transition: .4s;
    -webkit-transition: .4s;
    color: var(--mainTheme);
}
#projects-filter .mix {
    text-align: left;
    display: none;
}
.portfolio-box {
    margin-bottom: 24px;
    overflow: hidden;
}
.portfolio-view {
    position: relative;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    z-index: 1;
}
.portfolio-view:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.work-showcase .items .lg-react-element {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    overflow: hidden;
}
.work-showcase .items .lg-react-element a {
    overflow: hidden;
}
.work-showcase .items .lg-react-element a img {
    width: 100%; 
    height: auto;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
} 
.work-showcase .items .lg-react-element a:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
/*-- End --*/
/*-- Start --*/
.project-started {
    position: relative;
    display: block;
    padding: 120px 0 0;
    overflow: hidden;
    background-color: var(--colorBlack);
}
.project-started-shape-1 {
    position: absolute;
    top: -333px;
    right: 50px;
    width: 495px;
    height: 360px;
    mix-blend-mode: screen;
    border-radius: 30px;
    z-index: 1;
    background-color: var(--mainTheme);
    transform: rotate(45deg);
    animation: rectMove 9s linear 0ms infinite;
}
.project-started-shape-2 {
    position: absolute;
    top: -175px;
    right: -350px;
    width: 495px;
    height: 360px;
    background-color: var(--colorBlack);
    mix-blend-mode: soft-light;
    border-radius: 30px;
    transform: rotate(45deg);
    animation: rectMove 9s linear 1000ms infinite;
}
.project-started-shape-3 {
    position: absolute;
    z-index: 1;
    top: 313px;
    left: -184px;
    width: 495px;
    height: 360px;
    background-color: var(--colorWhite);
    mix-blend-mode: soft-light;
    border-radius: 30px;
    transform: rotate(45deg);
    animation: rectMove 9s linear 0ms infinite;
}
.project-started-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--mainTheme);
    border-radius: 10px;
    padding: 90px 80px 90px;
    overflow: hidden;
}
.project-started-left {
    position: relative;
    display: block;
}
.project-started-title {
    font-size: 40px;
    font-weight: 600;
    color: var(--colorWhite);
    text-transform: uppercase;
    line-height: 48px;
}
.project-started-right {
    position: relative;
    display: block;
}
.project-started-btn {
    color: var(--colorBlack);
    background-color: var(--colorWhite);
}
.project-started-btn:hover {
    color: var(--colorWhite);
    background-color: var(--colorBlack);
}
/*-- End --*/
@media(max-width: 1400px){
    .work-showcase {
        padding: 127px 0 100px;
    }
    .project-started {
        padding: 100px 0 0;
    }
}
@media(max-width: 1200px) {
    .breadcrumb-section .breadcrumb-text h1 {
        font-size: 54px;
    }
    .breadcrumb-section {
        padding-top: 200px;
        padding-bottom: 190px;
    }
    .work-showcase {
        padding: 107px 0 80px;
    }
    .project-started {
        padding: 80px 0 0;
    }
    .portfolio-view .portfolio-content {
        padding: 30px 30px;
    }
    .work-showcase .items .lg-react-element {
        grid-template-columns: repeat(2, 1fr);
    } 
    .project-started-title {
        font-size: 38px;
    }
}
@media(max-width: 992px) {
    .breadcrumb-section .breadcrumb-text h1 {
        font-size: 50px;
    }
    .breadcrumb-section {
        padding-top: 160px;
        padding-bottom: 150px;
    } 
    .work-showcase {
        padding: 87px 0 60px;
    }
    .work-showcase .controls {
        display: block;
    }
    .portfolio-view img {
        height: 336px;
    }
    .project-started {
        padding: 60px 0 0;
    }
    .project-started-box {
        padding: 70px 50px 70px;
    }
    .project-started-left {
        width: 60%;
    }
    .project-started-title {
        font-size: 36px;
    }
} 
@media(max-width: 768px) {
    .breadcrumb-section .breadcrumb-text h1 {
        font-size: 44px;
    }
    .breadcrumb-section {
        padding: 140px 0 130px;
    } 
    .breadcrumb-section .breadcrumb-nav {
        width: 65%;
    }
    .work-showcase {
        padding: 77px 0 50px;
    }
    .project-started {
        padding: 50px 0 0;
    }
    .project-started-box {
        display: block;
        text-align: center;
    }
    .project-started-left {
        width: 100%;
    }
    .project-started-title {
        margin-right: 0px;
        margin-bottom: 30px;
        font-size: 36px;
    }
    .work-showcase .controls {
        margin-bottom: 60px;
    }
    .work-showcase .items .lg-react-element {
        grid-template-columns: repeat(1, 1fr);
    } 
}
@media(max-width: 576px) {
    .breadcrumb-section .breadcrumb-text h1 {
        font-size: 36px;
    }   
    .breadcrumb-section {
        padding-top: 160px;
        padding-bottom: 130px;
    }
    .work-showcase .controls {
        margin-bottom: 50px;
    }
    .portfolio-view img {
        height: auto;
    }
    .project-started-box {
        padding: 50px 30px 50px;
    }
    .project-started-title {
        font-size: 32px;
    }
}
@media(max-width: 460px){
    .work-showcase .controls {
        text-align: left;
        display: grid;
    }
    .controls button {
        text-align: left;
    }
}