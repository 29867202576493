/*-- Start --*/
.contact-us {
    padding: 140px 0 0;
    background-color: var(--colorWhite);
}
.contact-us .section-head {
    margin-bottom: 50px;
}
.contact-us .section-head h6 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--mainTheme);
    margin-bottom: 15px;
    letter-spacing: 1px;
}
.contact-us .section-head h2 {
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colorBlack);
}
.contact-box {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9ebed;
    padding-top: 30px;
    padding-bottom: 30px;
}
.contact-box img {
    width: 65px;
    height: 65px;
    background-color: var(--mainTheme);
    border-radius: 5px;
    text-align: center;
    padding: 15px;
}
.content-text {
    margin-left: 20px;
}
.contact-box {
    margin-right: 30px;
}
.contact-box p {
    font-size: 16px;
    font-weight: 500;
    color: var(--colorGrey);
    margin-bottom: 0;
}
.contact-box h5 {
    font-size: 18px;
    font-weight: 600;
    color: var(--colorBlack);
    margin-top: 5px;
    margin-bottom: 0;
}
.contact-box h5 a {
    font-size: 22px;
    font-weight: 600;
    color: var(--colorBlack);
}
.contact-box h5 a:hover {
    color: var(--mainTheme);
}
.contact-form .form-control {
    background-color: #f2f2f2;
    border-color: transparent;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 20px 30px;
    height: 58px;
    font-size: 18px;
}
.contact-form textarea.form-control {
    height: auto;
}
.contact-form input:focus, .contact-form textarea:focus {
    border-color: var(--mainTheme);
}
.send-message-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    outline: none !important;
    background-color: var(--mainTheme);
    font-size: 16px;
    font-weight: 600;
    color: var(--colorWhite);
    text-transform: uppercase;
    padding: 17px 60px;
    transition: all 0.3s linear;
    overflow: hidden;
    z-index: 1;
    letter-spacing: 0.1em;
    border-radius: 5px;
}
.send-message-btn input[type="submit"] {
    position: relative;
    display: inline-block;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
    color: var(--colorWhite);
    text-transform: uppercase;
    padding: 0;
    border: none;
}
.send-message-btn:hover {
    color: var(--colorWhite);
}
.send-message-btn:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--colorBlack);
    transform: scale(0);
    transition: all 0.2s linear;
    border-radius: 5px;
    opacity: 1;
    z-index: -1;
}
.send-message-btn:hover:after {
    opacity: 1;
    transform: scale(1);
    transition: all 0.4s linear;
}
.map {
    margin-top: 120px;
}
.contact-map {
    position: relative;
    display: block;
    border: none;
    height: 450px;
    width: 100%;
}
/*-- End --*/

@media(max-width: 1400px) { 
    .contact-us {
        padding: 110px 0 0;
    }
    .contact-us .section-head h2 {
        font-size: 56px;
    }
    .map {
        margin-top: 100px;
    }
}
@media(max-width: 1200px) {
    .contact-us {
        padding: 90px 0 0;
    }
    .contact-us .section-head h2 {
        font-size: 50px;
    }
    .contact-us .section-head {
        margin-bottom: 60px;
    }
    .map {
        margin-top: 80px;
    }
}
@media(max-width: 992px) {
    .contact-us {
        padding: 70px 0 0;
    }
    .contact-us .section-head h2 {
        font-size: 46px;
    }
    .contact-box {
        margin-right: 0px;
    }
    .contact-us .section-head {
        margin: 50px 0;
    }
    .contact-form {
        margin-top: 40px;
    }
    .map {
        margin-top: 60px;
    }
} 
@media(max-width: 768px) {
    .contact-us {
        padding: 60px 0 0;
    }
    .contact-us .section-head h2 {
        font-size: 42px;
    }
    .contact-us .section-head {
        margin-bottom: 40px;
    }
    .map {
        margin-top: 50px;
    }
}
@media(max-width: 576px) {
    .contact-us .section-head h6 {
        font-size: 22px;
    }
    .contact-us .section-head h2 {
        font-size: 32px;
    }
    .send-message-btn {
        text-align: center;
    }
} 
@media(max-width: 400px){
    .contact-box.d-flex {
        display: block!important;
    }
    .content-text {
        margin-left: 0px;
        margin-top: 20px;
        text-align: center;
    }
    .contact-box img {
        margin: 0 auto;
        display: block;
    }
}