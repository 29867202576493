/*-- Start --*/
.career {
    padding: 140px 0 110px;
    background-color: var(--colorWhite);
}
.career .section-head {
    margin-bottom: 80px;
}
.career .section-head h6 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--mainTheme);
    margin-bottom: 15px;
    letter-spacing: 1px;
}
.career .section-head h2 {
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colorBlack);
}
.career .mb-30 {
    margin-bottom: 30px;
}
.career-box {
    position: relative;
    display: block;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    background-color: var(--colorWhite);
    box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 5%);
    border: 1px solid #e2e0e5;
    border-radius: 5px;
/*    height: 100%;*/
}
.career-box:before {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 5px;
    content: "";
    background-color: var(--mainTheme);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 1;
}
.career-box h4 {
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--colorBlack);
    padding: 20px 40px 20px;
    margin-bottom: 0;
}
.job-text {
    padding: 0 40px 20px;
}
.job-text ul li {
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
    font-size: 20px;
    color: var(--colorGrey);
}
.job-text ul li::before {
    color: var(--colorBlack);
    font-size: 18px;
    content: "\f105";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    margin-right: 15px;
}
.job-text ul li:last-child {
    margin-bottom: 0px;
}
.job-text h5 {
    font-size: 20px;
    font-weight: 500;
    color: var(--colorBlack);
    margin-bottom: 20px;
}
.job-text p {
    font-size: 18px;
    color: var(--colorGrey);
    margin-bottom: 0;
}
.career-box .thm-btn-black {
    border-radius: 5px 5px 0px 5px;
}
.career .modal-dialog {
    max-width: 40%;
}
.career .modal-header .btn-close {
    padding: 0;
    margin: 0;
}
.career .modal-header {
    border: none;
    padding: 30px 30px 0;
}
.career .modal-title {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--colorBlack);
    text-align: center;
    margin: 0 auto;
}
.career .modal-body {
    padding: 30px;
}
.career .btn-close:hover {
    color: var(--mainTheme);
    opacity: 1;
}
.job-apply .form-control {
    height: 69px;
    background-color: #f2f2f2;
    border-color: transparent;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 20px 30px;
    font-size: 18px;
}
.job-apply textarea.form-control {
    height: auto;
}
.job-apply input:focus, .job-apply textarea:focus {
    border-color: var(--mainTheme);
}
/*-- End --*/
@media(max-width: 1400px) {
    .career {
        padding: 120px 0 90px;
    }
    .career .section-head h2 {
        font-size: 56px;
    }
    .career .modal-dialog {
        max-width: 60%;
    }
}
@media(max-width: 1200px) {
    .career {
        padding: 100px 0 50px;
    }
    .career .section-head h2 {
        font-size: 50px;
    }
    .career .modal-dialog {
        max-width: 70%;
    }
}
@media(max-width: 992px) {
    .career {
        padding: 80px 0 30px;
    }
    .career .section-head h2 {
        font-size: 46px;
    }
    .career .section-head {
        margin-bottom: 70px;
    }
} 
@media(max-width: 768px) {
    .career {
        padding: 71px 0 20px;
    }
    .career .section-head h2 {
        font-size: 42px;
    }
    .career .section-head {
        margin-bottom: 60px;
    }
}
@media(max-width: 576px) {
    .career .section-head h2 {
        font-size: 32px;
    }
    .career .section-head h6 {
        font-size: 22px;
    }
    .career .section-head {
        margin-bottom: 50px;
    }
    .career-box h4 {
        padding: 20px 30px 20px;
        font-size: 22px;
    }
    .job-text {
        padding: 0 30px 20px;
    }
    .job-text ul li {
        font-size: 18px;
    }
    .career .modal-dialog {
        max-width: 100%;
    }
}  