/*-- Start Slider --*/
.home-slider .item img {
    display: block;
    width: 100%;
    height: auto;
}
.home-slider .owl-carousel .item {
    position: relative;
}
.home-slider .owl-carousel .item>img {
    display: inline-block;
}
.home-slider .item-caption {
    text-align: left;
}
.home-slider .owl-carousel .item .item-caption {
    bottom: 0;
    top: 0;
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    /*width: 80%;*/
    max-width: 1140px;
}
.home-slider .owl-carousel .item .item-caption-inner {
    display: table;
    width: 100%;
    height: 100%;
}
.home-slider .owl-carousel .item .item-caption-wrap {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.home-slider .owl-carousel .item::before {
    content: "";
    position: absolute;
    background-color: #000000;
    width: 100%;
    height: 100%;
    opacity: .7;
    left: 0;
    top: 0;
    bottom: 0;
}
.home-slider .item-caption p {
    color: var(--colorWhite);
    font-size: 20px;
    text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.25);
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0;
    padding: 5px 20px;
    display: inline-block;
    background-color: #000000;
    opacity: .7;
}
.home-slider .owl-item.active p {
    -webkit-animation: 1s .6s fadeInUp both;
    animation: 1s .6s fadeInUp both;
}
.home-slider .owl-item.active img {
    animation: scale 40s linear infinite;
}
.home-slider .item-caption h1 {
    font-size: 90px;
    font-weight: 600;
    color: var(--colorWhite);
    margin: 0 15px 30px 15px;
    letter-spacing: 1px;
    text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
}
.home-slider .owl-item.active h1 {
    -webkit-animation: 2s .6s fadeInUp both;
    animation: 2s .6s fadeInUp both;
}
.home-slider .item-caption h6 {
    font-size: 40px;
    font-weight: 400;
    color: var(--colorWhite);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 15px 10px 15px;
}
.home-slider .owl-item.active h6 {
    -webkit-animation: 2s .6s fadeInDown both;
    animation: 2s .6s fadeInDown both;
}
.home-slider .owl-item.active a {
    -webkit-animation: 2s .6s fadeInUp both;
    animation: 2s .6s fadeInUp both;
}
.home-slider .owl-theme .owl-nav {
    margin: 0px;
}
.home-slider .owl-centered .owl-item {
    display: table-cell;
    vertical-align: middle;
}
.home-slider .owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    right: 100px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    color: var(--mainTheme);
    background-color: var(--colorWhite);
    font-size: 18px;
    opacity: 1;
    border-radius: 10px 0 0 0;
    bottom: -30px;
    width: 100px;
    height: 60px;
    margin: 0;
}
.home-slider .owl-carousel .owl-nav button.owl-prev:hover {
    color: var(--colorWhite);
    background-color: var(--mainTheme);
    transition: all 500ms ease;
}
.home-slider .owl-carousel .owl-nav button.owl-next {
    position: absolute;
    right: 0px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    color: var(--mainTheme);
    background-color: var(--colorWhite);
    font-size: 18px;
    opacity: 1;
    border-radius: 10px;
    bottom: -30px;
    width: 100px;
    height: 60px;
    border-radius: 0;
    margin: 0;
}
.home-slider .owl-carousel .owl-nav button.owl-next:hover {
    color: var(--colorWhite);
    background-color: var(--mainTheme);
    transition: all 500ms ease;
}
.home-slider .owl-carousel .owl-nav .prev-slide {
    text-transform: uppercase;
    font-family: 'Teko', sans-serif;
    font-weight: 500;
    line-height: 60px;
}
.home-slider .owl-carousel .owl-nav .next-slide {
    text-transform: uppercase;
    font-family: 'Teko', sans-serif;
    font-weight: 500;
    line-height: 60px;
}
.home-slider .owl-theme .owl-dots {
    position: absolute;
    bottom: 50px;
    left: 200px;
    right: 200px;
}
.home-slider .owl-theme .owl-dots .owl-dot span {
    width: 14px;
    height: 14px;
    margin: 5px 7px;
    background: var(--colorBlack);
    display: block;
    transition: opacity .2s ease;
    border-radius: 30px;
    border: 2px solid var(--colorWhite);
}
.home-slider .owl-theme .owl-dots .owl-dot.active span,
.home-slider .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--colorWhite);
    -webkit-transition: .4s;
    transition: .4s;
}
.home-slider-shape-1 {
    position: absolute;
    top: -182px;
    right: 125px;
    width: 495px;
    height: 350px;
    background-color: var(--mainTheme);
    mix-blend-mode: screen;
    opacity: 0;
    transform: rotate(45deg) translateY(-200px);
    border-radius: 30px;
    z-index: 2;
}
.home-slider .owl-item.active .home-slider-shape-1 {
    opacity: 1;
    transform: rotate(45deg) translateY(0px);
    transition: all 1000ms ease;
    transition-delay: 1500ms;
}
.home-slider-shape-2 {
    position: absolute;
    top: -70px;
    right: -242px;
    width: 495px;
    height: 350px;
    background-color: var(--colorBlack);
    mix-blend-mode: soft-light;
    transform: rotate(45deg) translateY(-200px);
    border-radius: 30px;
    opacity: 0;
    z-index: 1;
}
.home-slider .owl-item.active .home-slider-shape-2 {
    opacity: 1;
    transform: rotate(45deg) translateY(0px);
    transition: all 1000ms ease;
}
.home-slider-shape-3 {
    position: absolute;
    top: 221px;
    right: -429px;
    width: 495px;
    height: 350px;
    background-color: var(--colorWhite);
    mix-blend-mode: soft-light;
    transform: rotate(45deg) translateY(-200px);
    border-radius: 30px;
    opacity: 0;
    z-index: 1;
}
.home-slider .owl-item.active .home-slider-shape-3 {
    opacity: 1;
    transform: rotate(45deg) translateY(0px);
    transition: all 1000ms ease;
    transition-delay: 2000ms;
}
/*-- End Slider --*/
/*-- Start --*/
.our-services {
    margin-bottom: 140px;
}
.services-head-bg {
    background-color: var(--colorBlack);
    padding: 140px 0 200px;
    background-image: url(../assets/images/home/service-bg.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.our-services .section-head h6 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--mainTheme);
    margin-bottom: 15px;
    letter-spacing: 1px;
}
.our-services .section-head h2 {
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colorWhite);
}
.services-section {
    box-shadow: 0px 0px 136px 0px rgb(0 0 0 / 16%);
    background: var(--colorWhite);
    margin-top: -130px;
}
.services-section .col-lg-4 {
    padding: 0;
}
.service-item {
    box-shadow: none;
    padding-top: 85px;
    margin-top: 0;
    position: relative;
}
.service-item {
    background: var(--colorWhite);
    padding: 60px 47px;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    transition: all 0.3s linear;
}
.service-item::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    background: #e1e1e1;
    transform: translateY(-50%);
    width: 1px;
    height: 375px;
}
.services-section .col-lg-4:last-child .service-item::before {
    display: none;
}
.service-item .service-icon {
    position: relative;
    z-index: 1;
}
.service-item .service-icon::before {
    content: "";
    position: absolute;
    left: 25px;
    top: -35px;
    background-image: url(../assets/images/home/ser-shape.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.service-item .service-icon .icon-image {
    font-size: 92px;
    color: var(--mainTheme);
    transition: all 0.3s linear;
}
.service-item .service-content h3 {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--colorBlack);
    margin-top: 30px;
}
.service-item .service-content h3:hover {
    color: var(--mainTheme);
}
.service-item .service-content p {
    font-size: 16px;
    line-height: 30px;
    margin-top: 20px;
    transition: all 0.3s linear;
}
.service-item .service-content .link-btn {
    margin-top: 20px;
}
.link-btn {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #0a0c1c;
}
.link-btn i {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 50%;
    background: var(--mainTheme);
    color: var(--colorWhite);
    display: inline-block;
    margin-left: 5px;
}
/*-- End --*/
/*-- Start --*/
.who-we-are {
    padding: 50px 0 0 0;
}
.about-img-wrap {
    position: relative;
    padding-right: 30px;
}
.about-img-wrap .shape-1 {
    position: absolute;
    top: -50px;
    left: -25px;
    z-index: -1;
}
.about-img-wrap .about-img.image-1 {
    position: relative;
    overflow: hidden;
}
.about-img-wrap .about-img {
    position: relative;
    z-index: 1;
}
.about-img-wrap .about-img::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(17, 56, 100, 0.5);
    width: 100%;
    height: 100%;
}
.about-img-wrap .about-img .image {
    width: 100%;
    height: auto;
}
.about-img-wrap .about-img.image-1 .shape-2 {
    position: absolute;
    left: -50px;
    bottom: -190px;
    width: 330px;
    height: 330px;
    border: 3px solid var(--colorWhite);
    border-radius: 50%;
}
.about-img-wrap .about-img.image-1 .shape-2::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid var(--colorWhite);
    border-radius: 50%;
    transform: scale(0.85);
}
.about-img-wrap .about-img-right {
    margin-top: 35px;
}
.about-img-wrap .about-img-right .shape-2 {
    border: 12px solid var(--mainTheme);
    margin-bottom: 20px;
}
.section-title .sub-title {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--mainTheme);
    margin-bottom: 15px;
    letter-spacing: 1px;
}
.section-title .title {
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colorBlack);
}
.about-content {
    padding-left: 85px;
}
.about-content p {
    font-size: 16px;
    line-height: 34px;
    color: var(--colorGrey);
    margin-top: 50px;
    padding-right: 45px;
}
.about-content .about-list {
    margin-top: 40px;
}
.about-content .about-list ul li i {
    font-size: 20px;
    color: var(--mainTheme);
    margin-right: 15px;
}
.about-content .about-list ul li {
    font-size: 20px;
    line-height: 36px;
    font-weight: 500;
    color: var(--colorBlack);
    display: flex;
}
/*-- End --*/
/*-- Start --*/
.work-showcase {
    padding: 140px 0;
    background-color: var(--colorWhite);
}
.work-showcase .section-head {
    margin-bottom: 80px;
}
.work-showcase .section-head h6 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--mainTheme);
    margin-bottom: 15px;
    letter-spacing: 1px;
}
.work-showcase .section-head h2 {
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colorBlack);
}
.portfolio-box {
    margin-bottom: 24px;
    overflow: hidden;
}
.portfolio-view {
    position: relative;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    z-index: 1;
}
.portfolio-view:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.portfolio-gallery .lg-react-element {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
}
.portfolio-gallery .lg-react-element a {
    overflow: hidden;
}
.portfolio-gallery .lg-react-element a img {
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}
.portfolio-gallery .lg-react-element a:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
/*-- End --*/
/*-- Start --*/
.project-review {
    background: var(--mainTheme);
    overflow: hidden;
    position: relative;
    text-align: center;
    z-index: 1;
    padding: 100px 0;
}
.project-review:before {
    background: url(../assets/images/home/process-bg.png) no-repeat 50%;
    background-size: cover;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.project-review .shape-1 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -10;
}
.project-review .shape-1 svg {
    fill: #0c61a5;
}
.project-review .shape-2 {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -10;
}
.project-review .shape-2 svg {
    fill: #0c61a5;
}
.project-count-box {
    border-right: 1px solid #ffffff2b;
}
.project-review .row .col-lg-3:last-child .project-count-box {
    border-right: none;
}
.counter-one-icon {
    position: relative;
    z-index: 1;
    width: 118px;
    height: 118px;
    background-color: #0c61a5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 12px;
}
.project-count-box:hover .counter-one-icon:before {
    transform: scaleX(1);
}
.counter-one-icon:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-radius: 50%;
    background-color: var(--colorBlack);
    transform: scale(0);
    transform-origin: center;
    transform-style: preserve-3d;
    transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
    z-index: -1;
}
.counter-one-icon img {
    position: relative;
    display: inline-block;
    transition: all 500ms linear;
    transition-delay: 0.1s;
    transform: scale(1);
}
.project-count-box:hover .counter-one-icon:before {
    transform: scaleX(1);
}
.project-count-box:hover .counter-one-icon span {
    transform: scale(0.9);
}
.project-count-box h3 {
    font-size: 50px;
    color: var(--colorWhite);
}
.project-count-box h4 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #7cb3df;
}
/*-- End ---*/
/*-- Start --*/
.business {
    background: url(../assets/images/home/business-bg.webp) center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 100px 0;
    overflow: hidden;
    z-index: 1;
    position: relative;
    display: block;
}
.counter-one-shape-1 {
    position: absolute;
    top: -220px;
    right: 80px;
    width: 495px;
    height: 360px;
    background-color: var(--mainTheme);
    mix-blend-mode: screen;
    border-radius: 30px;
    z-index: 1;
    transform: rotate(48deg);
    animation: rectMove2 9s linear 0ms infinite;
}
.counter-one-shape-2 {
    position: absolute;
    top: -90px;
    right: -284px;
    width: 495px;
    height: 350px;
    background-color: var(--colorBlack);
    mix-blend-mode: soft-light;
    border-radius: 30px;
    z-index: -1;
    transform: rotate(45deg);
    animation: rectMove 9s linear 0ms infinite;
}
.counter-one-shape-3 {
    position: absolute;
    bottom: 90px;
    left: -125px;
    width: 265px;
    height: 185px;
    background-color: var(--mainTheme);
    /*mix-blend-mode: soft-light;*/
    border-radius: 30px;
    z-index: -1;
    transform: rotate(45deg);
    animation: rectMove 9s linear 0ms infinite;
}
.business-content h2 {
    font-size: 60px;
    font-weight: 600;
    color: var(--colorWhite);
    margin-bottom: 50px;
}
@keyframes rectMove {
    0% {
        transform: rotate(45deg) translateY(-0px);
    }
    25% {
        transform: rotate(43deg) translateY(-20px);
    }
    50% {
        transform: rotate(45deg) translateY(-40px);
    }
    75% {
        transform: rotate(43deg) translateY(-20px);
    }
    100% {
        transform: rotate(45deg) translateY(-0px);
    }
}
@keyframes rectMove2 {
    0% {
        transform: rotate(48deg) translateY(-0px);
    }
    25% {
        transform: rotate(46deg) translateY(-20px);
    }
    50% {
        transform: rotate(48deg) translateY(-40px);
    }
    75% {
        transform: rotate(46deg) translateY(-20px);
    }
    100% {
        transform: rotate(48deg) translateY(-0px);
    }
}
/*-- End --*/
/*-- Start --*/
.testimonial {
    position: relative;
    padding: 120px 0px;
    background: var(--colorWhite) url(../assets/images/home/pattern-bg.png) left top repeat;
    overflow: hidden;
}
.testimonial .section-head h6 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--mainTheme);
    margin-bottom: 15px;
    letter-spacing: 1px;
}
.testimonial .section-head h2 {
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colorBlack);
}
.testimonials-box {
    border-style: solid;
    border-width: 1px;
    border-color: #e9ebee;
    border-radius: 8px;
    background-color: var(--colorWhite);
    padding: 45px;
    padding-bottom: 63px;
    padding-top: 30px;
    margin-bottom: 77px;
    position: relative;
    transition: all 500ms ease;
}
.testimonials-box-content p {
    margin: 0;
    font-size: 16px;
    line-height: 34px;
    color: #686a6f;
    margin-bottom: 15px;
    font-weight: 400;
}
.testimonials-box-content h4 {
    text-transform: uppercase;
    font-size: 24px;
    color: var(--colorBlack);
    font-weight: 600;
}
.testimonials-box-content h6 {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--mainTheme);
    margin: 0;
}
.testimonials-box .testimonials-image {
    display: inline-flex;
    padding: 10px;
    border: 1px solid #e9ebee;
    border-radius: 8px;
    position: absolute;
    left: 45px;
    bottom: 0;
    transform: translateY(50%);
    transition: all 500ms ease;
}
.testimonials-box .testimonials-image img {
    width: 73px;
    height: 73px;
    border-radius: 8px;
}
.testimonial .owl-nav {
    position: absolute;
    left: -375px;
    top: 75%;
}
.testimonial .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 10px !important;
    color: var(--mainTheme);
    background-color: var(--colorWhite);
    box-shadow: 0 0 10px 0 #e7e5e5;
    font-size: 24px;
    font-weight: 600;
    transition: all .2s ease;
    width: 60px;
    height: 60px;
    border-radius: 40px;
}
.testimonial .owl-theme .owl-nav .owl-next:hover {
    color: var(--colorWhite);
    background-color: var(--mainTheme);
}
.testimonial .owl-theme .owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 10px !important;
    color: #592c00;
    background-color: var(--colorWhite);
    box-shadow: 0 0 10px 0 #e7e5e5;
    font-size: 24px;
    font-weight: 600;
    transition: all .2s ease;
    width: 60px;
    height: 60px;
    border-radius: 40px;
}
.testimonial .owl-theme .owl-nav .owl-prev:hover {
    color: var(--colorWhite);
    background-color: var(--mainTheme);
}
.testimonial .owl-theme .owl-dots .owl-dot span {
    display: block;
    width: 12px;
    height: 12px;
    background: var(--colorWhite);
    border: 2px solid var(--mainTheme);
    -webkit-transition: .4s;
    transition: .4s;
    opacity: 1;
    margin: 5px 5px;
}
.testimonial .owl-theme .owl-dots .owl-dot.active span {
    display: block;
    width: 12px;
    height: 12px;
    background: var(--mainTheme);
    -webkit-transition: .4s;
    transition: .4s;
    opacity: 1;
}
/*-- End --*/
/*-- Start --*/
.project-started {
    position: relative;
    display: block;
    padding: 120px 0;
    overflow: hidden;
    background-color: var(--colorBlack);
}
.project-started-shape-1 {
    position: absolute;
    top: -333px;
    right: 50px;
    width: 495px;
    height: 360px;
    background-color: var(--mainTheme);
    mix-blend-mode: screen;
    border-radius: 30px;
    z-index: 1;
    transform: rotate(45deg);
    animation: rectMove 9s linear 0ms infinite;
}
.project-started-shape-2 {
    position: absolute;
    top: -175px;
    right: -350px;
    width: 495px;
    height: 360px;
    background-color: var(--colorBlack);
    mix-blend-mode: soft-light;
    border-radius: 30px;
    transform: rotate(45deg);
    animation: rectMove 9s linear 1000ms infinite;
}
.project-started-shape-3 {
    position: absolute;
    z-index: 1;
    top: 313px;
    left: -184px;
    width: 495px;
    height: 360px;
    background-color: var(--colorWhite);
    mix-blend-mode: soft-light;
    border-radius: 30px;
    animation: rectMove 9s linear 0ms infinite;
    transform: rotate(45deg);
}
.project-started-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--mainTheme);
    border-radius: 10px;
    padding: 90px 80px 90px;
    overflow: hidden;
}
.project-started-left {
    position: relative;
    display: block;
}
.project-started-title {
    font-size: 40px;
    color: var(--colorWhite);
    text-transform: uppercase;
    line-height: 48px;
    margin-right: 50px;
}
.project-started-right {
    position: relative;
    display: block;
}
.project-started-btn {
    color: var(--colorBlack);
    background-color: var(--colorWhite);
}
.project-started-btn:hover {
    color: var(--colorWhite);
    background-color: var(--colorBlack);
}
/*-- End --*/
@media(max-width: 1400px) {
    .our-services {
        margin-bottom: 100px;
    }
    .services-head-bg {
        padding: 100px 0 200px;
    }
    .our-services .section-head h2 {
        font-size: 56px;
    }
    .section-title .title {
        font-size: 56px;
    }
    .work-showcase .section-head h2 {
        font-size: 56px;
    }
    .counter-one-shape-1 {
        width: 245px;
        height: 320px;
        top: -220px;
        right: 80px;
    }
    .counter-one-shape-2 {
        width: 438px;
    }
    .service-item {
        padding: 60px 40px;
    }
    .testimonial {
        padding: 100px 0px;
    }
    .testimonial .section-head h2 {
        font-size: 56px;
    }
    .business-content h2 {
        font-size: 56px;
    }
}
@media(max-width: 1200px) {
    .home-slider-shape-1 {
        width: 344px;
        height: 266px;
        top: -182px;
        right: 125px;
    }
    .home-slider-shape-2 {
        width: 416px;
        height: 292px;
        right: -242px;
    }
    .home-slider .item-caption h1 {
        font-size: 70px;
    }
    .home-slider .item-caption h6 {
        font-size: 34px;
    }
    .our-services {
        margin-bottom: 80px;
    }
    .our-services .section-head h2 {
        font-size: 50px;
    }
    .services-head-bg {
        padding: 80px 0 200px;
    }
    .service-item {
        padding: 60px 30px 40px;
    }
    .service-item .service-content h3 {
        font-size: 26px;
    }
    .section-title .title {
        font-size: 50px;
    }
    .about-content {
        padding-left: 0;
    }
    .about-img-wrap .shape-1 {
        left: -15px;
    }
    .our-services {
        margin-bottom: 80px;
    }
    .about-content p {
        padding-right: 0px;
    }
    .portfolio-gallery .lg-react-element {
        grid-template-columns: repeat(2, 1fr);
    }
    .work-showcase .section-head h2 {
        font-size: 50px;
    }
    .project-review {
        padding: 80px 0;
    }
    .testimonial {
        padding: 80px 0px;
    }
    .testimonial .section-head h2 {
        font-size: 50px;
    }
    .testimonial .owl-nav {
        left: -253px;
        top: 90%;
    }
    .business {
        padding: 80px 0;
    }
    .business-content h2 {
        font-size: 50px;
    }
    .project-started {
        padding: 100px 0 80px;
    }
}
@media(max-width: 992px) {
    .home-slider .item-caption h1 {
        font-size: 46px;
    }
    .home-slider .item-caption h6 {
        font-size: 26px;
    }
    .home-slider .item img {
        height: 500px;
    }
    .home-slider-shape-1 {
        width: 257px;
        height: 245px;
        top: -182px;
        right: 91px;
    }
    .home-slider-shape-2 {
        width: 369px;
        height: 255px;
        right: -242px;
    }
    .home-slider-shape-3 {
        width: 200px;
        height: 200px;
        top: 176px;
        right: -131px;
    }
    .our-services {
        margin-bottom: 60px;
    }
    .our-services .section-head h2 {
        font-size: 46px;
    }
    .services-head-bg {
        padding: 60px 0 180px;
    }
    .service-item {
        padding: 60px 50px 40px;
    }
    .service-item::before {
        width: 100%;
        height: 2px;
        right: 0%;
        top: 100%;
    }
    .section-title .title {
        font-size: 46px;
    }
    .about-img-wrap {
        padding-right: 0px;
        margin-bottom: 30px;
    }
    .work-showcase {
        padding: 100px 0;
    }
    .work-showcase .section-head h2 {
        font-size: 46px;
    }
    .project-review {
        padding: 60px 0;
    }
    .project-count-box {
        margin-bottom: 30px;
    }
    .project-review .row .col-lg-3:nth-child(2) .project-count-box {
        border-right: none;
    }
    .testimonial {
        padding: 60px 0px;
    }
    .testimonial .section-head h2 {
        font-size: 46px;
    }
    .testimonial .owl-nav {
        left: 0;
        top: 15px;
        right: 0;
    }
    .testimonial .owl-theme .owl-nav .owl-prev {
        right: 66px;
    }
    .testimonial .owl-theme .owl-nav .owl-next {
        left: auto;
        right: 0;
    }
    .testimonials-box {
        margin-top: 76px;
    }
    .counter-one-shape-3 {
        width: 207px;
        height: 172px;
    }
    .business {
        padding: 60px 0;
    }
    .business-content h2 {
        font-size: 46px;
    }
    .project-started-box {
        padding: 70px 50px 70px;
    }
    .project-started {
        padding: 100px 0;
    }
}
@media(max-width: 768px) {
    .home-slider .owl-carousel .owl-nav button.owl-prev {
        right: 80px;
        bottom: -25px;
        width: 80px;
        height: 50px;
    }
    .home-slider .owl-carousel .owl-nav button.owl-next {
        bottom: -25px;
        width: 80px;
        height: 50px;
    }
    .home-slider .owl-carousel .owl-nav .prev-slide {
        line-height: 50px;
    }
    .home-slider .owl-carousel .owl-nav .next-slide {
        line-height: 50px;
    }
    .home-slider .item-caption h1 {
        font-size: 38px;
    }
    .our-services {
        margin-bottom: 50px;
    }
    .our-services .section-head h2 {
        font-size: 42px;
    }
    .services-head-bg {
        padding: 50px 0 170px;
    }
    .section-title .title {
        font-size: 42px;
    }
    .services-section .col-lg-4 {
        padding: 0 12px;
    }
    .about-content p {
        margin-top: 30px;
    }
    .about-content .about-list {
        margin-top: 25px;
    }
    .work-showcase {
        padding: 80px 0 50px;
    }
    .work-showcase .section-head h2 {
        font-size: 42px;
    }
    .work-showcase .section-head {
        margin-bottom: 60px;
    }
    .project-review {
        padding: 50px 0;
    }
    .portfolio-gallery .lg-react-element {
        grid-template-columns: repeat(1, 1fr);
    }
    .project-count-box {
        border-right: none;
    }
    .project-review .shape-1 {
        top: -90px;
    }
    .project-count-box h3 {
        font-size: 46px;
    }
    .testimonial {
        padding: 50px 0px;
    }
    .testimonial .section-head h2 {
        font-size: 42px;
    }
    .business {
        padding: 50px 0;
    }
    .business-content h2 {
        font-size: 42px;
    }
    .project-started {
        padding: 80px 0;
    }
    .project-started-box {
        display: block;
        text-align: center;
    }
    .project-started-title {
        margin-right: 0px;
        margin-bottom: 30px;
        font-size: 36px;
    }
}
@media(max-width: 576px) {
    .home-slider .owl-theme .owl-dots {
        bottom: 15px;
        left: 0px;
        right: 0px;
    }
    .home-slider .owl-theme .owl-nav {
        display: none;
    }
    .home-slider .item-caption h1 {
        font-size: 32px;
    }
    .home-slider-shape-1,
    .home-slider-shape-2,
    .home-slider-shape-3 {
        display: none;
    }
    .home-slider .item img {
        height: 400px;
    }
    .service-item::before {
        right: 0%;
        width: 100%;
    }
    .service-item {
        padding: 60px 30px 30px;
    }
    .our-services .section-head h6 {
        font-size: 22px;
    }
    .our-services .section-head h2 {
        font-size: 32px;
    }
    .service-item .service-content h3 {
        font-size: 25px;
    }
    .section-title .title {
        font-size: 32px;
    }
    .section-title .sub-title {
        font-size: 22px;
    }
    .about-img-wrap .col-sm-6 {
        width: 50%;
    }
    .about-img-wrap .shape-1 {
        left: -9px;
        height: 160px;
    }
    .about-img-wrap .about-img.image-1 .shape-2 {
        left: 10px;
        bottom: -114px;
        width: 190px;
        height: 190px;
    }
    .work-showcase .section-head h2 {
        font-size: 32px;
    }
    .work-showcase .section-head h6 {
        font-size: 22px;
    }
    .testimonial .section-head h2 {
        font-size: 32px;
    }
    .testimonial .owl-theme .owl-nav .owl-prev {
        font-size: 20px;
        width: 50px;
        height: 50px;
        right: 70px;
    }
    .testimonial .owl-theme .owl-nav .owl-next {
        font-size: 20px;
        width: 50px;
        height: 50px;
        right: 9px;
    }
    .testimonials-box {
        padding: 30px 30px 60px;
    }
    .testimonials-box .testimonials-image {
        left: 30px;
    }
    .testimonial .owl-nav {
        top: 15px;
    }
    .counter-one-shape-1 {
        width: 207px;
        height: 293px;
    }
    .counter-one-shape-3 {
        width: 166px;
        height: 136px;
    }
    .counter-one-shape-2 {
        width: 364px;
        height: 310px;
    }
    .business-content h2 {
        font-size: 32px;
    }
    .project-started-box {
        padding: 70px 30px 70px;
    }
    .project-started-title {
        font-size: 32px;
    }
}